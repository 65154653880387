import { polyfillPromise } from '@ambita/ambita-components-core';
import ConfigDataService from '../dataServices/config';
import polyfillClosest from '../polyfills/closest';
import polyfillIsFinite from '../polyfills/isFinite';
import polyfillNodeListForEach from '../polyfills/nodelistForEach';

const loadAppCode = function (): Promise<any> {
  return new Promise((resolve) => {
    import('../app').then((app) => {
      resolve(app);
    });
  });
};

export const bootstrap = async function (): Promise<void> {
  polyfillPromise();
  polyfillClosest();
  polyfillNodeListForEach();
  polyfillIsFinite();

  await ConfigDataService.fetchConfig();
  const app = await loadAppCode();
  app.initialize();
};
